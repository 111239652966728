import React, { useState } from "react";
import Pricejeans from "./price-compo/Pricejeans";
import PriceTrouser from "./price-compo/PriceTrouser";
import Pricejacket from "./price-compo/Pricejacket";
import PriceTshirt from "./price-compo/Pricetshirt";
import PriceShirt from "./price-compo/PriceShirt";
import Pricedresses from "./price-compo/Pricedresses";
import Pricecurtain from "./price-compo/Pricecurtain";
import Priceabbaya from "./price-compo/Priceabbaya";
import Pricemiscellanous from "./price-compo/Pricemiscellanous";
import i1 from "./images/price1.png";
import i2 from "./images/price2.png";
import i3 from "./images/price3.png";
import i4 from "./images/price4.png";
import './App.css'; // Assuming you have a CSS file for additional styles

function Pricelist() {
  const [activeTab, setActiveTab] = useState("TROUSERS");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const renderComponent = () => {
    switch (activeTab) {
      case "TROUSERS":
        return <PriceTrouser />;
      case "JEANS":
        return <Pricejeans />;
      case "JACKETS":
        return <Pricejacket />;
      case "T-SHIRTS":
        return <PriceTshirt />;
      case "SHIRTS":
        return <PriceShirt />;
      case "ABBAYAS":
        return <Priceabbaya />;
      case "DRESSES":
        return <Pricedresses />;
      case "CURTAINS":
        return <Pricecurtain />;
      case "MISCELLANEOUS":
        return <Pricemiscellanous />;
      default:
        return null;
    }
  };

  return (
<div className="container ">
  <div className="row mt-4">
    <div className="col-12 text-center">
      <h3 className="font" style={{ fontWeight: 400 }}>Our Alteration</h3>
      <h1 className="font" style={{ fontWeight: 400 }}>PRICE LIST</h1>
    </div>
    <div className="col-12">
      <div className="d-flex flex-row flex-wrap justify-content-around font mt-4 mb-5 tabs font">
        {['TROUSERS', 'JEANS', 'JACKETS', 'T-SHIRTS', 'SHIRTS', 'ABBAYAS', 'DRESSES', 'CURTAINS', 'MISCELLANEOUS'].map((tab) => (
          <p
            key={tab}
            className={`nav-link-price ${activeTab === tab ? 'active' : ''}`}
            onClick={() => handleTabClick(tab)}
            style={{
              backgroundColor: activeTab === tab ? '#343a40' : 'transparent',
              color: activeTab === tab ? '#f8f9fa' : 'inherit',
              padding: '5px 15px',
              borderRadius: '5px',
              cursor: 'pointer'
            }}
          >
            {tab}
          </p>
        ))}
      </div>
    </div>
  </div>
  {renderComponent()} {/* Render the component based on active tab */}
  <div className="row-8  " style={{backgroundColor:'rgb(241 241 241)'}}><h5 className="text-center font py-2">For Pick & Drop service the order needs to be minimum of 100 dhs</h5></div>
  <div className="row mt-5 mb-5 justify-content-center">
    <div className="col-12 text-center">
      <h3 className="font mt-3" style={{ fontWeight: 400 }}>
        Get Inspired by Style and Peek Behind The Scenes
      </h3>
      <h1 className="font mb-4" style={{ fontWeight: 500 }}>
        FOLLOW US ON INSTAGRAM
      </h1>
    </div>
    <div className="col-12 col-sm-6 col-md-3 mb-3">
      <img className="img-fluid" src={i1} alt="" />
    </div>
    <div className="col-12 col-sm-6 col-md-3 mb-3">
      <img className="img-fluid" src={i2} alt="" />
    </div>
    <div className="col-12 col-sm-6 col-md-3 mb-3">
      <img className="img-fluid" src={i3} alt="" />
    </div>
    <div className="col-12 col-sm-6 col-md-3 mb-3">
      <img className="img-fluid" src={i4} alt="" />
    </div>
  </div>
</div>
 
);
}

export default Pricelist;
