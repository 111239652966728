import React from "react";
import img1 from"./images/1.png";
import img2 from"./images/2.png";
import img3 from"./images/3.png";
import event from "./images/event-costume.png"
import cushions from "./images/cushions.png"
import repair from "./images/repairs.png"
import zipperreplace from "./images/zipper.png"
import leatherziper from "./images/leather-zipper.png"
import uniform from "./images/Uniform.png"
import button from "./images/button.png"
import swim from "./images/swimsuit.png"
import i1 from "./images/Asset1.png"
import i2 from "./images/Asset 2.png"
import i3 from "./images/Asset 3.png"
import i4 from "./images/Asset 4.png"
import i5  from "./images/Asset 5.png"
import i6 from "./images/Asset 6.png"
import i7 from "./images/Asset 7.png"
import i8 from "./images/Asset 8.png"
import i9 from "./images/Asset 9.png"
import i10 from "./images/Asset 10.png"
import i11 from "./images/Asset 11.png"
import i12 from "./images/Asset 12.png"


function Littlethings(){
    return(
        <>
<div className="container mt-5 mb-5">
  <div className="row mt-5 gx-0">
    <div className="col-12 col-md-8 mt-5">
      <h2 className="text-start font" style={{ fontWeight: 700 }}>HIGH QUALITY FABRICS</h2>
      <p className="text-start">Whether it's a bespoke suit requiring fine Italian fabrics or a custom-stitched gown needing an exact color match, we source the highest-quality fabrics for your dream outfit.</p>
    </div>
    
    <div className="col-12 col-md-4">
      <div className="row gx-0">
        <div className="col-3 px-0">
          <img className="img-fluid " src={i1} alt="" />
        </div>
        <div className="col-3 px-0">
          <img className="img-fluid " src={i2} alt="" />
        </div>
        <div className="col-3 px-0">
          <img className="img-fluid " src={i3} alt="" />
        </div>
        <div className="col-3 px-0">
          <img className="img-fluid " src={i4} alt="" />
        </div>
        <div className="col-3 px-0">
          <img className="img-fluid " src={i5} alt="" />
        </div>
        <div className="col-3 px-0">
          <img className="img-fluid " src={i6} alt="" />
        </div>
        <div className="col-3 px-0">
          <img className="img-fluid " src={i7} alt="" />
        </div>
        <div className="col-3 px-0">
          <img className="img-fluid " src={i8} alt="" />
        </div>
        <div className="col-3 px-0">
          <img className="img-fluid " src={i9} alt="" />
        </div>
        <div className="col-3 px-0">
          <img className="img-fluid " src={i10} alt="" />
        </div>
        <div className="col-3 px-0">
          <img className="img-fluid " src={i11} alt="" />
        </div>
        <div className="col-3 px-0">
          <img className="img-fluid " src={i12} alt="" />
        </div>
      </div>
    </div>
  </div>
</div>

 {/* our expertise       */}
 <div className="container mb-5 mt-5">
  <h2 className="text-center font mb-4" style={{ fontWeight: "700" }}>Our Expertise</h2>
  <div className="row">
    <div className="col-6 col-sm-4 col-md-3 mb-2 ">
    <img src={event} className="img-fluid"  alt="Event" />

      <img src={cushions} className="img-fluid mt-2 " alt="Cushions" />
    </div>
    <div className="col-6 col-sm-4 col-md-3 mb-2">
      <img src={repair} className="img-fluid " alt="Repair" />
      <img src={zipperreplace} className="img-fluid mt-2 " alt="Zipper Replace" />
    </div>
    <div className="col-6 col-sm-4 col-md-3 mb-2">
      <img src={leatherziper} className="img-fluid " alt="Leather Zipper" />
      <img src={uniform} className="img-fluid mt-2 "alt="Uniform" />
    </div>
    <div className="col-6 col-sm-4 col-md-3 mb-2">
      <img src={button} className="img-fluid " alt="Button" />
      <img src={swim} className="img-fluid mt-2 " alt="Swim" />
    </div>
</div>

</div>











        {/* embracing culture */}
        <div className="container mb-5 mt-5">
        <h4 className="text-center font ">Embracing Culture</h4>
        <h2 className="text-center font" style={{ fontWeight: "700" }}>OUR APPROACH TO PERFECTION</h2>
        <div className="row mt-5">
          <div className="col-12 col-md-4 mb-4 text-center">
            <img src={img1} className="mb-3" style={{ height: "70px", width: "70px" }} alt="Express Service" />
            <h6 className="mb-3 font">Express Service</h6>
            <p className="font">Our Express Service guarantees swift and precise shortening for trousers. Trust us to tailor your bottoms quickly without compromising on quality, ensuring a perfect fit every time.</p>
          </div>
          <div className="col-12 col-md-4 mb-4 text-center">
            <img src={img3} className="mb-3" style={{ height: "70px", width: "70px" }} alt="Expertise" />
            <h6 className="mb-3 font">Expertise</h6>
            <p className="font">Our expertise is rooted in years of experience, blending traditional techniques with modern innovation to deliver unparalleled quality and precision in every service offered.</p>
          </div>
          <div className="col-12 col-md-4 mb-4 text-center">
            <img src={img2} className="mb-3" style={{ height: "70px", width: "70px" }} alt="Convenience" />
            <h6 className="mb-3 font">Convenience</h6>
            <p className="font">Our visiting home tailor service prioritizes your convenience. Enjoy personalized fittings in the comfort of your home, ensuring a seamless and tailored experience.</p>
          </div>
        </div>
      </div>

        </>
    )
}export default Littlethings