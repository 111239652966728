import React from "react";
import img1 from "./images/Asset 1.png";

function Visitingtailor() {
    return (
        <div className="container mt-3 ">
            <h4 className="text-center font " style={{ fontSize: "20px" }}>
                Tailoring at Your Home or Office
            </h4>
            <h3 className="text-center font" style={{ fontWeight: "700" }}>
                VISITING TAILOR SERVICE
            </h3>
            <div className="row mb-5 justify-content-center">
                <div className="col-12 col-md-4 text-center text-md-left mb-3 mb-md-0">
                    <img className="rounded-4 img-fluid" src={img1} alt="pic" />
                </div>
                <div className="col-12 col-md-8">
                    <div className="text-start py-4">
                        <h4 className="font text-start mb-3">Tailoring at your Door Step</h4>
                        <p className="mb-5 font" style={{  }}>
                            We're excited to share that we've elevated personalized tailoring to new heights, ensuring our services are even more accessible and convenient for you.
                        </p>
                        <p className="mb-5 font" style={{ }}>
                            Whether you need a bespoke suit, an exquisite evening gown, or just alterations from your recent shopping, our visiting tailor service ensures a smooth, hassle-free experience. We prioritize your schedule and preferences, ensuring you feel confident and stylish in clothes that reflect your personality.
                        </p>
                        <p className="font mb-5" style={{ }}>
                            Experience the convenience of high-quality tailoring at your doorstep with My Tailor. Schedule your appointment today!
                        </p>
                        <div className="d-flex flex-column flex-md-row">
                          
                            <button className="transition px-5 mx-2 rounded-4 btn btn-dark font">
      <a 
        href="https://wa.me/+971555843316?text=Hello%2C%20I%20would%20like%20to%20know%20more%20about%20your%20services." 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        LET'S TALK NOW
      </a>
    </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Visitingtailor;
