import React from "react";
function Pricedresses(){
    return(
        <>
            <div className="container">
    <div className="row">
        <div className="col-12 col-sm-6 col-md-4 px-2 mb-4" >
            <h5 className="font mb-2" style={{ fontWeight: 500 }}>SHORTENING</h5>
            <table className="table mt-4 table-border-top">
                <tbody className="font">
                    <tr>
                        <td>Machine Stitch</td>
                        <td>AED 40</td>
                    </tr>
                    <tr>
                        <td>Hand Stitch</td>
                        <td>AED 50</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="col-12 col-sm-6 col-md-4 px-2 mb-4">
            <h5 className="font mb-2" style={{ fontWeight: 500 }}>LENGTHENING</h5>
            <table className="table mt-4 table-border-top">
                <tbody className="font">
                    <tr>
                        <td>Machine Stitch</td>
                        <td>AED 40</td>
                    </tr>
                    <tr>
                        <td>Hand Stitch</td>
                        <td>AED 50</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="col-12 col-sm-6 col-md-4 px-2 mb-4">
            <h5 className="font mb-2" style={{ fontWeight: 500 }}>TIGHTEN & TAPERS</h5>
            <table className="table mt-4 table-border-top">
                <tbody className="font">
                    <tr>
                        <td>Machine Stitch</td>
                        <td>AED 40</td>
                    </tr>
                    <tr>
                        <td>Hand Stitch</td>
                        <td>AED 50</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>
        </>
    )
}export default Pricedresses