import logo from './logo.svg';
import './App.css';
import Footer from './Footer';
import Visitingtailor from './Visitingtailor';
import Littlethings from './Littlethings';
import Pricelist from './Pricelist';
import Aboutus from './Aboutus';
import Service from './Service';
import Cardreview from './Cardreview';
function App() {
  return (
    <div className="App">
   {/* <Cardreview></Cardreview> */}
      <Service></Service>
      <Aboutus></Aboutus> 
      {/* <Pricelist></Pricelist> */}
      <Littlethings></Littlethings> 
   <Visitingtailor></Visitingtailor>   
 <Footer></Footer>
    </div>
  );
}

export default App;
