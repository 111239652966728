import React from "react";
import i1 from "./images/google.png"
import i2 from "./images/Asset 45.png"
import i3 from "./images/Asset 46.png"
import i4 from "./images/contact.png"
import i5 from './images/contact_1.png'
import i6 from './images/contact_2.png'
import i7 from './images/contact_3.png'
import Pricelist from "./Pricelist";
import {BrowserRouter} from "react-router-dom"
import {HashLink as Link} from "react-router-hash-link"
function Aboutus() {
  return (
    <>
      {/* LITTLE ABOUT US */}
      <div className="container mt-5 " id="about">
  <div className="row mb-2">
    <div className="col-12">
      <h4 className="text-center font mt-2" style={{ fontWeight: 500 }}>A Little</h4>
      <h2 className="text-center font mb-3" style={{ fontWeight: 700 }}>ABOUT US</h2>
    </div>
  </div>
  <div className="row">
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 mt-3 text-center">
      <img src={i4} alt="" width={300} className="img-fluid" />
    </div>
    <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 mt-3 text-start">
      <p className="font" style={{ fontWeight: 300 }}>
      Located in the heart of Dubai, 2nd December Street behind KFC satwa provide exceptional Tailoring services since 2006. Crafting each garment with a unique pattern tailored after ultra care consultations and hand finished using only the finest materials for a good fit. With a number selection of fabrics, a wide arrange of designs, and impeccable artistry, we create masterpieces that complement your style and reflect your personality. From simple alterations like shortening and hemming to major modifications, we ensure your clothes fit perfectly. Our priority is to sew a lot of fabric in a short amount of time without compromising quality and excellent service and always deliver on our promises.
      </p>
      <div className="d-flex flex-column mb-3">
        <div className="d-flex align-items-start">
          <img src={i5} alt="" width={55} height={50} className="me-3" />
          <div>
            <h4 className="font" style={{ fontWeight: 400 }}>Perfect Fit</h4>
            <p className="font">Every cut undergoes meticulous analysis to enhance the silhouette, preserving a sense of effortless elegance</p>
          </div>
        </div>

        <div className="d-flex align-items-start">
          <img src={i6} alt="" width={99} height={50} className="me-3" />
          <div>
            <h4 className="font" style={{ fontWeight: 400 }}>Expert Tailor and Female Staff</h4>
            <p className="font">Our experienced tailors boast decades of expertise, complemented by a knowledgeable female staff adept at understanding your requirements and taking precise measurements</p>
          </div>
        </div>

        <div className="d-flex align-items-start">
          <img src={i7} alt="" width={55} height={50} className="me-3" />
          <div>
            <h4 className="font" style={{ fontWeight: 400 }}>Quality Premium Fabrics</h4>
            <p className="font">We procure a wide range of fabrics, meticulously chosen to align with your preferences.</p>
          </div>
        </div>
      </div>
    </div>
  </div>




















        {/* REVIEW OF TAILOR */}
        <div className="row mt-5 mb-5">
  <div className="col-12 mt-5 mb-2">
    <h2 className="font text-center " style={{ fontWeight: 400 }}>
      TOP-RATED TAILORING SERVICES IN DUBAI.
    </h2>
    <div className="mt-2" id="pricelist">
<Pricelist></Pricelist>
</div>
  </div>
  <div className="row justify-content-center">
    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xsm-12 text-center mb-3">
      <img src={i1} alt="Google review" className="img-fluid" />
    </div>

    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center mb-3">
  <div className="card mx-3">
    <div className="card-body" style={{ backgroundColor: "#f4f4f4" }}>
      <div className="d-flex">
        <div>
          <img
            className="rounded-pill"
            width="45"
            src={i2}
            alt="Developer Logo"
          />
        </div>
        <div className="ml-2">
          <h4 className="p-0 m-0 text-start font mx-1">Iram Sevilla</h4>
          <p className="p-0 m-0 text-start font mx-1" style={{ fontWeight: 300, color: "#a2a2a2" }}>
            Local Guide . 17 reviews . 2 photos
          </p>
          <p className="text-start font m-0 p-0 mx-1" style={{ fontWeight: 300, color: "#a2a2a2" }}>
            8 month ago
          </p>
        </div>
      </div>
      <div className="d-flex" style={{ marginLeft: '5px' }}>
        <i className="bi bi-star-fill text-warning"></i>
        <i className="bi bi-star-fill text-warning"></i>
        <i className="bi bi-star-fill text-warning"></i>
        <i className="bi bi-star-fill text-warning"></i>
        <i className="bi bi-star-fill text-warning"></i>
        <i className="bi bi-star-half text-warning"></i>
      </div>
      <div className="mx-1 mt-1">
        <p className="text-start font">
          I love all the dresses they repaired for me, and they followed all my instructions on how I exactly wanted my dress to look
        </p>
      </div>
    </div>
  </div>
</div>



<div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 text-center mb-5 ">
  <div className="card mx-3">
    <div className="card-body" style={{ backgroundColor: "#f4f4f4" }}>
      <div className="d-flex">
       <div>
       <img
          className="rounded-pill"
          width="45"
          src={i3}
        />
       </div>
        <div className="ml-2">
          <h4 className="p-0 m-0 text-start font mx-1">Pablo Antonio Pinochet G.</h4>
          <p className="p-0 m-0 text-start font mx-1" style={{ fontWeight: 300, color: "#a2a2a2" }}>
            3 reviews
          </p>
          <p className="text-start font m-0 p-0 mx-1" style={{ fontWeight: 300, color: "#a2a2a2" }}>
            5 month ago
          </p>
        </div>
      </div>
      <div className="d-flex" style={{ marginLeft: '5px' }}>
        <i className="bi bi-star-fill text-warning"></i>
        <i className="bi bi-star-fill text-warning"></i>
        <i className="bi bi-star-fill text-warning"></i>
        <i className="bi bi-star-fill text-warning"></i>
        <i className="bi bi-star-fill text-warning"></i>
        <i className="bi bi-star-half text-warning"></i>
      </div>
      <div className="mx-1 mt-1">
        <p className="text-start font">
        I recommend Electra Tailoring, they are working excellent, doing great job in short time with unbelievable prices. 5 stars for them
        </p>
      </div>
    </div>
  </div>
</div>

  </div>
</div>























      </div>
      <div className="row section-bg">
  <div className="col-12 mt-5">
    <div className="row mt-5 justify-content-center">
      <div className="col-12">
        <h3 className="font text-center mb-0 mb-md-3" style={{ color: "#fff", fontWeight: 300 }}>
          DESIGN YOUR CUSTOM OUTFIT WITH YOUR SKILLED TAILORS AT
        </h3>
        <h3 className="font text-center mt-0 mt-md-3" style={{ color: "white", fontWeight: 300 }}>
          ELECTRA STORES OR ARRANGE FOR A HOME TAILOR VISIT.
        </h3>
        <div className="mt-5 mb-5 text-center">
        <BrowserRouter>
        <Link to='#contact' smooth className="nav-link text-light mb-3"style={{fontWeight:700}}> <button
  className="font btn btn-outline-light rounded-4 py-1 px-5 mt-0 mb-0 mt-md-3 mb-md-3"
  style={{ fontWeight: 400 }}
>
  GET IN TOUCH
</button>
       
</Link>
</BrowserRouter>
        </div>
      </div>
    </div>
  </div>
</div>

    </>
  )
} export default Aboutus