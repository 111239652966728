import React from "react";
import { useState } from "react";
import i1 from './images/alteration.png'
import i2 from './images/bshirt.png'
import i3 from './images/bsuit.png'
import i4 from './images/gowns.png'
import i5 from "./images/logo3.png"
import {BrowserRouter} from "react-router-dom"
import {HashLink as Link} from "react-router-hash-link"
function Service(){

  const [activeNav, setActiveNav] = useState('');

  const handleNavClick = (navItem) => {
    setActiveNav(navItem);
  }
  return(
        <>
  <div className=" m-0">

  <div className="row header-bg mb-5" style={{ marginTop: '-20px' }} id="home">
        <div className="container">
        <div className="row d-flex flex-wrap justify-content-around align-items-center border-bottom border-light">
            <div className="col-12 col-md-6 text-center text-md-left mt-2">
              <img src={i5} width={190} alt="" className="img-fluid" />
            </div>
            <div className="col-12 col-md-6 text-center text-md-right mt-3 mt-md-0 mb-3 mb-md-0 mb-lg-0">
    <button style={{ background: '#ffffff8e' }} className="btn rounded-4 text-white header-btn">
        Call us: 0555843316
    </button>
</div>

          </div>
         <BrowserRouter>
          <nav className="p-3">
            <ul className="nav justify-content-center font">
               <li className="nav-item">
               <Link to='#home' smooth className="nav-link text-light">Home</Link>
              </li>
              <li className="nav-item">
              <Link to='#about' smooth className="nav-link text-light">About</Link>
             
              </li>
              <li className="nav-item">
              <Link to='#services' smooth className="nav-link text-light">Our Services</Link>
                
              </li>
              <li className="nav-item">
               <Link to='#contact' smooth className="nav-link text-light">Contact Us</Link>
              </li>
            </ul>
          </nav>
          </BrowserRouter>


        </div>

<div className="col-12">
  <div className="mb-5 mt-4">
    <h2 className="font text-light" style={{fontWeight:300}}>Sewing with Love</h2>
    <h2 className="font text-light " style={{fontWeight:300}}>WE ARE ELECTRA TAILORING</h2>
    </div>
<div className="mb-5">
<button className="btn btn-outline light text-light font rounded-4 border-light mb-5 px-5 py-2" style={{ transition: 'background-color 0.3s, color 0.3s' }}>
<a 
        href="https://wa.me/+971555843316?text=Hello%2C%20I%20would%20like%20to%20know%20more%20about%20your%20services." 
        target="_blank" 
        rel="noopener noreferrer"
        style={{ textDecoration: 'none', color: 'inherit' }}
      >
        LET'S TALK NOW
      </a>
    </button>
</div>
</div>
   </div>
  

   <div className="container">
  <div className="row mt-5 mb-5 g-0">
    <div className="col-12" id="services">
      <h4 className="font text-center" style={{ fontWeight: 500 }}>Tailoring </h4>
      <h3 className="font text-center mb-4" style={{ fontWeight: 700 }}>SERVICES</h3>
    </div>
    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 d-flex justify-content-center my-2 my-md-0">
      <img src={i1} alt="" className="img-fluid rounded-5" />
    </div>
    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 d-flex justify-content-center my-2 my-md-0">
      <img src={i2} alt="" className="img-fluid rounded-5" />
    </div>
    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 d-flex justify-content-center my-2 my-md-0">
      <img src={i3} alt="" className="img-fluid rounded-5" />
    </div>
    <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 d-flex justify-content-center my-2 my-md-0">
      <img src={i4} alt="" className="img-fluid rounded-5" />
    </div>
  </div>
</div>


</div>

        </>
    )
}export default Service