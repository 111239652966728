import React from "react";
import logo from "./images/logo2.png";
import logo2 from "./images/footer-whatsapp.png" 
import {BrowserRouter} from "react-router-dom"
import {HashLink as Link} from "react-router-hash-link"
function Footer() {
    return (
        <>
            <div className="container-fluid p-0">
                <div className="row" id="contact">
                    <div className="col-12 d-flex flex-column justify-content-center align-items-center map-bg " style={{ }}>
                        <p className="font m-0 my-3" style={{ fontSize: "30px", fontWeight: "400" }}>Contact Us</p>
                        <p className="font mb-1">Home Services and Appointments</p>
                        <p className="font m-0 mb-1" style={{ fontSize: "30px", fontWeight: "400" }}>Store Location</p>
                        <a 
      href="https://maps.app.goo.gl/jTcHnTgsosM7FqSw9" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ textDecoration: "none" }}  >
  <i className="bi bi-geo-alt" style={{ fontSize: "4rem", cursor:'pointer', color:'black '}}></i>
    </a>
                       
                        <p className="font text-center">2nd December Street behind KFC Satwa</p>
                     
                        <a 
      href="https://maps.app.goo.gl/jTcHnTgsosM7FqSw9" 
      target="_blank" 
      rel="noopener noreferrer" 
      style={{ textDecoration: "none" }}  >
      <button 
        className="btn px-4 rounded-3 font shadow mb-3" 
        style={{ background: "#a33b3b", color: "white" }}
      ><i className="bi bi-geo-alt mx-1"></i>
        Get Direction
      </button>
    </a>
                    </div>
                    <div className="container justify-content-center align-items-center p-4" style={{ background: "#232323" }}>
                        <div className="row text-center text-white justify-content-center align-items-center">
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12">
                                <div className="align-items-center">
                                <img id="img-margin-top" style={{marginTop:'-27px'}} src={logo} alt="Logo" width={190} />
                                <div className="">
                                    <i className="bi bi-facebook mx-2" style={{ fontSize: '2rem' }}></i>
                                    <i className="bi bi-instagram mx-2" style={{ fontSize: '2rem' }}></i>
                                </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-3">
                                <ul className="list-unstyled">
                                    <li className="font fs-4" style={{fontWeight:500,}}>About</li>
                                    <li className="font" style={{fontWeight:200}}>Located on 2nd December Street, behind KFC Satwa, Dubai, we offer top-notch tailoring since 2006. Fine materials, perfect fits, quality service, timely delivery.</li>
                                </ul>
                            </div>
                            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 ">
                            <BrowserRouter>
                                <ul className="list-unstyled">
                                <Link to='#home' smooth className="nav-link text-light mb-3"style={{fontWeight:700}}>Home</Link>
                                <Link to='#about' smooth className="nav-link text-light mb-3"style={{fontWeight:700}}>About</Link>
                                <Link to='#services' smooth className="nav-link text-light mb-3"style={{fontWeight:700}}>Services</Link>
                                <Link to='#pricelist' smooth className="nav-link text-light mb-3"style={{fontWeight:700}}>Price List</Link>
                                </ul>
                                </BrowserRouter>
                            </div>
                    
                        </div>
                    </div>
                    
                </div>
                <footer class="text-center bg-black py-3" style={{ color: '#cecece'}}>
                <div class="container">
    <div class="row">
        <div class="col font">
            <p class="m-0">Designed & Developed by <span>  <a 
      href="https://envisionit.io/" 
      className="font" 
      style={{ fontWeight: 500, textDecoration: 'none',color:'white' }}
    >Envision</a></span></p>
        </div>
        <div class="col-auto">
        <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '1000' }}>
        <div style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: '1000' }}>
    <button style={{ border: 'none', background: 'none', padding: 0 }}>
    <a href="https://wa.me/+971555843316?text=Hello%2C%20I%20would%20like%20to%20know%20more%20about%20your%20services." target="_blank" rel="noopener noreferrer">
    <img src={logo2} alt="whatsapp-button" width={50} height={50} />
</a>

    </button>
</div>

</div>

        </div>
    </div>
</div>
    </footer>
            </div>
        </>
    );
}

export default Footer;
